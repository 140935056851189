import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoaderComponent } from './common/_components/loader/loader.component';
import { ModalComponent } from './modal/modal.component';
import { TranslatePipe } from './common';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderComponent, ModalComponent, TranslatePipe],
  exports: [CommonModule, LoaderComponent, ModalComponent, TranslatePipe],
})
export class SharedModule {}
