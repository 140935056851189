import { Pipe, PipeTransform } from '@angular/core';
import { BranchEndpointService } from './../_services/branch-endpoint.service';
import { COMMON_TRANSLATIONS, TranslationKeys } from '../translations';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private branchService: BranchEndpointService) {}

  transform(key: keyof TranslationKeys) {
    const locale = this.branchService?.details?.locale || 'en-US';

    // TODO report via sentry if key is missing
    return COMMON_TRANSLATIONS[locale]?.[key] || key;
  }
}
