<div *ngIf="!loading" class="recent-serving-counters">
  <div class="counter-column">
    <div class="label">{{ 'currentlyServing' | translate }}</div>
    <div *ngFor="let token of recentServingTokens; let i = index" [class.hidden]="i >= 3" class="token-number">
      {{ token.tokenNumber }}
    </div>
  </div>
  <div class="counter-column">
    <div class="label">{{ 'counter' | translate }}</div>
    <div *ngFor="let token of recentServingTokens; let i = index" [class.hidden]="i >= 3" class="counter-name">
      {{ token.counterName }}
    </div>
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
