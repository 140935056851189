import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BranchEndpointService } from '../_services/branch-endpoint.service';
import { Observable, of } from 'rxjs';
import { DataService } from '../_services';

@Injectable({
  providedIn: 'root',
})
export class BranchDataResolver implements Resolve<any> {
  constructor(private branchService: BranchEndpointService, private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.branchService.details) return of(this.branchService.details);

    const branchId = this.dataService.branchData();
    if (!branchId) return of(null);

    this.branchService.getBranchDetails(branchId, {
      success: () => {
        return of(this.branchService.details);
      },
    });
  }
}
