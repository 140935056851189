import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import { handleApiErrors } from '../_utils/api.util';
import { CounterCustomizationRes } from '../_types/customizations.types';
import { GetBranchQueuesResponse, GetPrintersResponse } from '../_types/api.types';

@Injectable()
export class MainPageEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService) {}

  private makeUrl(resourcePath) {
    return `${this.basicUrl}/${this.dataService.branchData()}/${resourcePath}`;
  }

  getPrintersData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/printers';
    return this.http.get<GetPrintersResponse>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getMonitorsData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/monitor_screens';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getMonitorOverviewsData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/monitor_overviews';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getFeedbackStandsData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/feedback_stands';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getQueueOverviewsData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/queue_overviews';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getRecentQueuesData() {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/recent_queues';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getQueuesData(includeCounters = false) {
    const resourcePath = includeCounters ? 'branch_queues?include=counters' : 'branch_queues';
    const url = this.makeUrl(resourcePath);
    return this.http.get<GetBranchQueuesResponse>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getCountersData(queue) {
    let url =
      this.basicUrl + '/' + this.dataService.branchData() + '/branch_queues/' + queue.id + '?include=counters';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getCounterCustomization() {
    const url = this.makeUrl('counter_customization');
    return this.http.get<CounterCustomizationRes>(url).pipe(catchError((err) => handleApiErrors(err)));
  }
}
