import { Component, OnInit, OnDestroy, NgZone, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import {
  DataService,
  Counter,
  Monitor,
  MonitorOverview,
  QueueOverview,
  BranchQueue,
  MainPageEndpointsService,
  PrinterEndpointsService,
  FeedbackStand,
  BranchEndpointService,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { getAutoRedirectMode, setAutoRedirectPath, toggleAutoRedirectMode } from '../common/autoRedirect.util';

declare var swal: any;

@Component({
  selector: 'main',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  providers: [MainPageEndpointsService, PrinterEndpointsService],
  encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements OnInit, OnDestroy {
  monitors;
  monitors_overviews;
  recent_queues;
  printers;
  queues;
  queue_overviews;
  feedback_stands;
  loggedIn;
  message;
  isConsoleCounter = false;
  isRoofCounter = false;
  queue;
  branchId: string;
  nameBasedCounterOptions = [];
  recentServingCountersOptions = [];

  ipAddress: FormGroup;
  submitted: boolean = false;

  showSingeQueueInformationCounters = false;
  autoRedirectMode = false;

  pattern =
    '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';

  constructor(
    private dataService: DataService,
    private router: Router,
    private authButtonService: AuthButtonService,
    private mainPageEndpoints: MainPageEndpointsService,
    private zone: NgZone,
    public branchEndpointService: BranchEndpointService
  ) {
    this.validateLogin();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.loadMonitors();
      this.loadMonitorOverviews();
      this.loadRecentQueues();
      this.loadQueues();
      this.loadQueueOverviews();
      this.loadFeedbackStands();
      this.loadNameBasedCounters();
      this.loadPrinters();
      this.loadRecentServingCountersOptions();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
    this.branchId = this.dataService.branchData();
    this.autoRedirectMode = getAutoRedirectMode() === 'true';
  }

  ngOnDestroy() {
    this.monitors = null;
    this.monitors_overviews = null;
    this.recent_queues = null;
    this.printers = null;
    this.queues = null;
    this.queue_overviews = null;
    this.feedback_stands = null;
    this.loggedIn = null;
    this.message = null;
    this.isConsoleCounter = null;
    this.isRoofCounter = null;
    this.queue = null;
    this.ipAddress = null;
    this.pattern = null;
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  loadPrinters() {
    this.mainPageEndpoints.getPrintersData().subscribe(
      ({ data }) => {
        this.printers = data?.map((it) => ({
          value: it.id,
          label: it.attributes.name,
        }));
      },
      (error) => this.alertMessage(error)
    );
  }

  loadMonitors() {
    this.mainPageEndpoints.getMonitorsData().subscribe(
      (res: any) => {
        this.monitors = [];
        const monitorsData = res.data;

        for (var i = 0; i < monitorsData.length; i++) {
          const monitorInstance = new Monitor(monitorsData[i]);
          this.monitors.push(monitorInstance);
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadQueues() {
    this.mainPageEndpoints.getQueuesData().subscribe(
      (data: any) => {
        this.queues = new Array<BranchQueue>();

        let queueData = data.data;
        for (var i = 0; i < queueData.length; i++) {
          let queueInstance = new BranchQueue();
          queueInstance.setBranchQueueData(queueData[i]);
          this.queues.push(queueInstance);
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadCounters(queueData) {
    this.mainPageEndpoints.getCountersData(queueData).subscribe(
      (data: any) => {
        if (parseInt(data.data.id) == queueData.id && data.included) {
          queueData.counters = new Array<Counter>();
          let counterData = data.included;
          for (var i = 0; i < counterData.length; i++) {
            let counterInstance = new Counter();
            counterInstance.setCounterData(counterData[i]);
            queueData.counters.push(counterInstance);
          }
          this.queue = queueData;
        } else {
          this.queue = new BranchQueue();
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadNameBasedCounters() {
    this.mainPageEndpoints.getQueuesData(true).subscribe(
      (res: any) => {
        const { included } = res;
        const counterNames = included.filter((it) => it.type === 'counters').map((it) => it.attributes.name);
        const distinctNames = Array.from(new Set(counterNames));
        const options = distinctNames.map((it: string) => ({ value: it.replace(' ', '_'), label: it }));
        this.nameBasedCounterOptions = options;
      },
      (error) => this.alertMessage(error)
    );
  }

  loadMonitorOverviews() {
    this.mainPageEndpoints.getMonitorOverviewsData().subscribe(
      (data: any) => {
        this.monitors_overviews = new Array<MonitorOverview>();

        let monitorOverviewsData = data.data;
        for (var i = 0; i < monitorOverviewsData.length; i++) {
          let monitorOverviewInstance = new MonitorOverview();
          monitorOverviewInstance.setMonitorOverviewData(monitorOverviewsData[i]);
          this.monitors_overviews.push(monitorOverviewInstance);
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadQueueOverviews() {
    this.mainPageEndpoints.getQueueOverviewsData().subscribe(
      (data: any) => {
        this.queue_overviews = new Array<QueueOverview>();

        let queueOverviewsData = data.data;
        for (var i = 0; i < queueOverviewsData.length; i++) {
          let queueOverviewInstance = new QueueOverview();
          queueOverviewInstance.setQueueOverviewData(queueOverviewsData[i]);
          this.queue_overviews.push(queueOverviewInstance);
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadFeedbackStands() {
    this.mainPageEndpoints.getFeedbackStandsData().subscribe(
      (data: any) => {
        this.feedback_stands = new Array<FeedbackStand>();

        let feedbackStandData = data.data;
        for (var i = 0; i < feedbackStandData.length; i++) {
          let feedbackStandInstance = new FeedbackStand();
          feedbackStandInstance.setFeedbackStandData(feedbackStandData[i]);
          this.feedback_stands.push(feedbackStandInstance);
        }
      },
      (error) => this.alertMessage(error)
    );
  }

  loadRecentQueues() {
    this.mainPageEndpoints.getRecentQueuesData().subscribe(
      (data: any) => {
        this.recent_queues = data?.data?.map?.((it) => ({ value: it.id, label: it.attributes.name }));
      },
      (error) => this.alertMessage(error)
    );
  }

  loadRecentServingCountersOptions() {
    this.recentServingCountersOptions = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
    ];
  }

  onPrinterSelect(data) {
    const url = `/branch/${this.branchId}/printer/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onMonitorSelect(data) {
    const url = `/branch/${this.branchId}/monitor/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onMonitorOverviewSelect(data) {
    const url = `/branch/${this.branchId}/monitor_overviews/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onRecentQueueSelect(data) {
    const url = `/branch/${this.branchId}/recent_queues/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onConsoleQueueSelect(data) {
    this.queue = null;
    this.isRoofCounter = true;
    this.showSingeQueueInformationCounters = false;
    this.isConsoleCounter = false;
    this.loadCounters(this.queues.filter((x) => x.id == data.value)[0]);
  }

  onSingleQueueInfoSelect(data) {
    const url = `/branch/${this.branchId}/single_queue_info_screen/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onRoofQueueSelect(data) {
    this.queue = null;
    this.isRoofCounter = false;
    this.showSingeQueueInformationCounters = false;
    this.isConsoleCounter = true;
    this.loadCounters(this.queues.filter((x) => x.id == data.value)[0]);
  }

  onQueueOverviewSelect(data) {
    const url = `/branch/${this.branchId}/queue_overviews/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onFeedbackStandSelect(data) {
    const url = `/branch/${this.branchId}/feedback_stands/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onNameBaseCounterSelect(data) {
    const url = `/branch/${this.branchId}/counter-display/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onLastXServingSelect(data) {
    const url = `/branch/${this.branchId}/recent_serving_counters`;
    const queryParams = `?count=${data.value}`;

    if (this.autoRedirectMode) setAutoRedirectPath(url + queryParams);
    this.router.navigate([url], { queryParams: { count: data.value } });
  }

  loadConsole(queue, queueCounter) {
    const url = `/branch/${this.branchId}/branch-queue/${queue.id}/employee-console/${queueCounter.value}/console`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  loadRoofCounter(queue, queueCounter) {
    const url = `/branch/${this.branchId}/branch-queue/${queue.id}/employee-console/${queueCounter.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  handleToFlexClick() {
    const url = `/view-board`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
  }

  isString(val) {
    return typeof val === 'string';
  }

  alertMessage(error = null) {
    swal({
      text: this.message,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Refresh the page!',
    }).then(() => {
      if (error && error[0].status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }

  handleUseAutoRedirectChange() {
    getAutoRedirectMode();
    const newValue = toggleAutoRedirectMode();
    if (newValue === 'false') setAutoRedirectPath('');
    this.autoRedirectMode = newValue === 'true';
  }
}
