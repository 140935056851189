import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PusherService, NewTokenData } from 'app/common/_services/pusher.service';
import { Subscription } from 'rxjs';
import { MainPageEndpointsService } from 'app/common/index';

interface RecentServingToken {
  tokenNumber: string;
  counterName: string;
}

@Component({
  selector: 'app-recent-serving-counters',
  templateUrl: './recent-serving-counters.component.html',
  styleUrls: ['./recent-serving-counters.component.scss'],
})
export class RecentServingCountersComponent implements OnInit, OnDestroy {
  timer: Subscription;
  recentServingTokens: RecentServingToken[] = [];
  customization: any;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private pusherService: PusherService,
    private mainPageEndpoints: MainPageEndpointsService
  ) {}

  ngOnInit(): void {
    this.initPusherSubscription();
    this.loadLatestServingTokens();
    this.loadCounterCustomization();
  }

  ngOnDestroy(): void {
    if (this.timer) this.timer.unsubscribe();
    this.timer = null;
  }

  getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    const { counterName, tokenNumber, status } = data.data.attributes;

    if (status === 'serving') {
      const newRecentServingToken = {
        tokenNumber: data.data.attributes.tokenNumber,
        counterName: data.data.attributes.counterName,
      };

      const existingCounterIndex = this.recentServingTokens.findIndex((it) => it.counterName === counterName);
      const isSameTokenNumber = this.recentServingTokens[existingCounterIndex]?.tokenNumber === tokenNumber;

      if (existingCounterIndex > -1 && isSameTokenNumber) {
        this.recentServingTokens.splice(existingCounterIndex, 1, newRecentServingToken);
        return;
      }

      this.recentServingTokens.pop();
      this.recentServingTokens.unshift(newRecentServingToken);
    }
  };

  loadLatestServingTokens() {
    this.mainPageEndpoints.getQueuesData(true).subscribe((data) => {
      const counters = data.included;
      this.recentServingTokens = counters
        .filter((counter) => ['serving', 'served'].includes(counter.attributes.currentTokenNumber?.status))
        .sort(
          (a, b) =>
            new Date(b.attributes.currentTokenNumber?.startTime).getTime() -
            new Date(a.attributes.currentTokenNumber?.startTime).getTime()
        )
        .slice(0, 3)
        .map((counter) => ({
          tokenNumber: counter.attributes.currentTokenNumber.tokenNumber,
          counterName: counter.attributes.name,
        }));
    });
  }

  loadCounterCustomization() {
    this.loading = true;

    this.mainPageEndpoints.getCounterCustomization().subscribe((res) => {
      const customization = res.data.attributes;

      if (!res?.data?.attributes) return;

      document.documentElement.style.setProperty('--counter-customization-text-color', customization.fontColor);
      document.documentElement.style.setProperty(
        '--counter-customization-background-color',
        customization.backgroundColor
      );

      setTimeout(() => (this.loading = false), 500);
    });
  }
}
