import { ApiCounter } from '../_types/api.types';
import {
  MonitorScreen,
  MonitorScreenViewType,
  BranchQueueData,
  MonitorIncludedType,
  PlaySoundBehaviourType,
} from './../_types';
import { BranchQueue } from './branch-queue';
import { Counter } from './counter';

export class Monitor {
  DEFAULT_FONT_FAMILY = 'Roboto';
  DEFAULT_FONT_COLOR = '#fff';
  DEFAULT_BACKGROUND_COLOR = '#384148';
  DEFAULT_FONT_SIZE = '43';
  DEFAULT_BORDER_COLOR = '#1f2343';

  public id: string;
  public value: string; // TODO remove if it always remain the same as id
  public name: string;
  public attachments: string[] = [];
  public counters: Counter[] = [];
  public queues: BranchQueue[] = [];

  public label: string;
  public viewType: MonitorScreenViewType;
  public mediaType: string;
  public enableCounters: boolean;
  public mediaId: string;
  public counterFontFamily: string;
  public tokenFontFamily: string;
  public counterFontColor: string;
  public tokenFontColor: string;
  public backgroundColor: string;
  public counterFontSize: string;
  public tokenFontSize: string;
  public borderColor: string;
  public itemType: string;
  public playTokenFetchSound: boolean;
  public playSoundOnAllBranchQueues: boolean;
  public playSoundBehaviourType: PlaySoundBehaviourType;

  constructor(data?: MonitorScreen, included: MonitorIncludedType = []) {
    if (!data) return;

    this.setMonitorData(data, included);
  }

  setMonitorData(data: MonitorScreen, included: MonitorIncludedType = []) {
    const { attributes, id } = data;

    this.id = id;
    this.name = attributes.name;
    this.value = this.id;
    this.label = this.name;
    this.itemType = attributes.itemType;
    this.attachments = attributes.media;
    this.viewType = attributes.view;
    this.mediaType = attributes?.mediaType?.toLowerCase() ?? 'image';
    this.enableCounters = attributes.enableCounters;
    this.mediaId = attributes.urlId;
    this.playTokenFetchSound = attributes?.playTokenFetchSound ?? false;
    this.playSoundOnAllBranchQueues = attributes?.playSoundOnAllBranchQueues ?? false;
    this.playSoundBehaviourType = attributes?.playSoundBehaviourType ?? 'people_in_queue_from_0_to_1';
    this.setFontValues(data);
    this.setMonitorItems(included);
  }

  setFontValues(data: MonitorScreen) {
    this.tokenFontFamily = data.attributes.tokenFontFamily || this.DEFAULT_FONT_FAMILY;
    this.counterFontFamily = data.attributes.counterFontFamily || this.DEFAULT_FONT_FAMILY;
    this.counterFontColor = data.attributes.counterFontColor || this.DEFAULT_FONT_COLOR;
    this.tokenFontColor = data.attributes.tokenFontColor || this.DEFAULT_FONT_COLOR;
    this.backgroundColor = data.attributes.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
    this.borderColor = data.attributes.borderColor || this.DEFAULT_BORDER_COLOR;
    this.counterFontSize = data.attributes.counterFontSize || this.DEFAULT_FONT_SIZE;
    this.tokenFontSize = data.attributes.tokenFontSize || this.DEFAULT_FONT_SIZE;
  }

  setMonitorItems(included: (ApiCounter | BranchQueueData)[]) {
    if (!included) return;

    const { itemType } = this;

    if (itemType === 'counter') {
      const counters = included.filter((it) => it.type === 'counters');
      this.setCountersData(counters);
    } else if (itemType === 'branch_queue') {
      const queues = included.filter((it) => it.type === 'branchQueues');
      this.setBranchQueuesData(queues, this.id);
    }
  }

  setCountersData(data) {
    const counters = [];

    data?.forEach((it) => {
      const counter = new Counter();
      counter.setCounterData(it);

      const oldCounterQueue = this.counters.filter((x) => x.id == counter.id)[0];
      if (oldCounterQueue) counter.animationFlag = oldCounterQueue.animationFlag;

      counters.push(counter);
    });

    this.counters = counters;
  }

  setBranchQueuesData(data, monitorId) {
    const newMonitorQueue = [];

    data.forEach((it) => {
      const queue = new BranchQueue();
      const monitorQueue = it.attributes?.monitorQueues?.find((x) => x.monitorScreenId == monitorId);

      const monitorQueueName = monitorQueue ? monitorQueue.displayName : '';
      queue.setBranchQueueData(it, monitorQueueName);

      const oldMonitorQueue = this.queues.filter((x) => x.id == queue.id)[0];
      if (oldMonitorQueue) queue.animationFlag = oldMonitorQueue.animationFlag;
      newMonitorQueue.push(queue);
    });

    this.queues = newMonitorQueue;
  }

  setAnimationFlag(data): any {
    if (data.attributes.itemType == 'counter' && data.attributes.activeCounterId) {
      let activeCounter = this.counters.filter((x) => x.id == data.attributes.activeCounterId)[0];
      if (activeCounter) activeCounter;
    } else if (this.itemType == 'branch_queue' && data.attributes.activeQueueId) {
      let activeQueue = this.queues.filter((x) => x.id == data.attributes.activeQueueId)[0];
      if (activeQueue) activeQueue;
    } else return null;
  }
}

export class MonitorAnimationData {
  public id;
  public date;
  public status;

  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.status = data.animationFlag;
    } else {
      this.status = false;
    }
    this.date = new Date();
  }

  setAnimationData(data) {
    this.id = data.id;
    this.status = true;
    this.date = new Date();
  }
}
