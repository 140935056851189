import { SharedModule } from './shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, Injectable, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';

import { SelectModule } from 'ng-select';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageComponent } from './login-page/login-page.component';
import { LogoutComponent } from './logout/logout.component';
import { EmployeeConsoleComponent } from './employee-console/employee-console.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MonitorOverviewComponent } from './monitor_overview/monitor_overview.component';
import { RecentQueueComponent } from './recent_queue/recent_queue.component';
import { SingleQueueInfoScreenComponent } from './single-queue-info-screen/single-queue-info-screen.component';
import { RoofCounterComponent } from './roof-counter/roof-counter.component';
import { AuthButtonComponent, AuthButtonService } from './auth-button/index';
import {
  AuthEndpointsService,
  DataService,
  HeaderService,
  TickerDirective,
  SecondsToDateTime,
  SecondsToMinuteSeconds,
  TwentyFourToZero,
  ReadableTyimePipe,
  EmployeeConsoleEndpointsService,
  MainPageEndpointsService,
} from './common/index';
import { MainPageComponent } from './main-page/main-page.component';
import { HeaderComponent } from './header/index';
import { ViewBoardComponent } from './view-board/view-board.component';
import { QueueOverviewComponent } from './queue-overview/queue-overview.component';
import { FeedbackStandComponent } from './feedback-stand/feedback-stand.component';
import { QuestionMoodComponent } from './question-mood/question-mood.component';
import { FeedbackStandSideComponent } from 'app/feedback-stand-side/feedback-stand-side.component';
import { PusherService } from './common/_services/pusher.service';
import { BranchEndpointService } from './common/_services/branch-endpoint.service';
import { HeaderInterceptor } from './http-interceptor';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { CounterConsoleComponent } from './view-board/counter-console/counter-console.components';
import { ViewBoardTokenListComponent } from './view-board/token-list/token-list.component';
import { ViewBoardQueueListComponent } from './view-board/queue-list/queue-list.component';
import { QueueService } from './common/_services/queue.service';
import { CancelAutoRedirectButtonComponent } from './cancel-auto-redirect-button/cancel-auto-redirect-button.component';
import { MonitorModule } from './monitor/monitor.module';
import { NameBasedCounterDisplayComponent } from './name-based-counter-display/name-based-counter-display.component';
import { CounterDisplayComponent } from './counter-display/counter-display.component';
import { AuthGuard } from './common/_guards/auth.guard';
import { RecentServingCountersComponent } from './recent-serving-counters/recent-serving-counters.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    EmployeeConsoleComponent,
    LogoutComponent,
    PageNotFoundComponent,
    SecondsToDateTime,
    MonitorOverviewComponent,
    RecentQueueComponent,
    SingleQueueInfoScreenComponent,
    RoofCounterComponent,
    AuthButtonComponent,
    MainPageComponent,
    HeaderComponent,
    TickerDirective,
    ViewBoardComponent,
    SecondsToMinuteSeconds,
    TwentyFourToZero,
    QueueOverviewComponent,
    FeedbackStandComponent,
    QuestionMoodComponent,
    FeedbackStandSideComponent,
    ReadableTyimePipe,
    ImageCarouselComponent,
    CounterConsoleComponent,
    ViewBoardTokenListComponent,
    ViewBoardQueueListComponent,
    CancelAutoRedirectButtonComponent,
    NameBasedCounterDisplayComponent,
    CounterDisplayComponent,
    RecentServingCountersComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SelectModule,
    BrowserAnimationsModule,
    MonitorModule,
    SharedModule,
  ],
  providers: [
    AuthButtonService,
    AuthEndpointsService,
    DataService,
    HeaderService,
    PusherService,
    BranchEndpointService,
    EmployeeConsoleEndpointsService,
    MainPageEndpointsService,
    QueueService,
    AuthGuard,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ logErrors: true }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
