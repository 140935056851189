import { NewTokenData, PusherService } from '../common/_services/pusher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainPageEndpointsService } from './../common/_services/main-page-endpoints.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { BranchEndpointService, DataService } from '../common';
import { CounterAttributes } from '../common/_types';
import { CounterCustomizationAttributes } from '../common/_types/customizations.types';
import {
  tokenIntvertColorFlashAnimation,
  tokenIntvertColorFlashAnimationDone,
  triggerTokenIntvertColorFlashAnimation,
} from '../common/common.animation';
import { ApiCounter } from '../common/_types/api.types';

declare var swal: any;

type CounterItem = Pick<
  CounterAttributes,
  'displayName' | 'peopleInQueue' | 'name' | 'currentTokenNumber' | 'nextTokenSoundEnabled'
> & {
  id: number;
};

@Component({
  selector: 'app-counter-display',
  templateUrl: './counter-display.component.html',
  styleUrls: ['./counter-display.component.scss'],
  animations: [tokenIntvertColorFlashAnimation()],
})
export class CounterDisplayComponent implements OnInit {
  public urlCounterId: number;
  public urlBranchQueueId: number;

  public counters: CounterItem[] = [];
  public showIdleScreen = true;
  public customization: CounterCustomizationAttributes;
  public tokenAnimationFlag = false;
  public tokenAnimationState = 'original';
  public latestTokenNumber = '';
  public idleScreenTimeout;

  public triggerTokenAnimation = () => {
    triggerTokenIntvertColorFlashAnimation(this, 'timeout', 'tokenAnimationFlag', 'tokenAnimationState');
  };

  public tokenAnimationDone = () => {
    tokenIntvertColorFlashAnimationDone(this, 'tokenAnimationFlag', 'tokenAnimationState');
  };

  constructor(
    public mainPageEndpointsService: MainPageEndpointsService,
    public route: ActivatedRoute,
    public router: Router,
    public dataService: DataService,
    public branchService: BranchEndpointService,
    public pusherService: PusherService,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.urlCounterId = +params['counterId'];
      this.urlBranchQueueId = +params['branchQueueId'];

      this.initPusherSubscription();
      this.loadData();
      this.loadCounterCustomization();
      this.setIdleScreenTimeout(false);
    });
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  loadData() {}
  handleNewTokenData = (payload: NewTokenData) => {};

  loadCounterCustomization() {
    this.mainPageEndpointsService.getCounterCustomization().subscribe((res) => {
      this.customization = res.data.attributes;
    });
  }

  setLatestTokenNumber() {
    const counterWithLatestToken = this.counters.slice(1).reduce((acc, val) => {
      const { status, startTime, tokenNumber } = val.currentTokenNumber || {};
      const startTimeDate = new Date(startTime);
      const currentStartTimeDate = new Date(acc?.currentTokenNumber?.startTime);

      const isShowable = ['serving', 'served'].includes(status);
      const isStartTimeLater = startTimeDate > currentStartTimeDate;
      const isNoCurrentStartTimeDate = !acc?.currentTokenNumber?.startTime;

      if ((isShowable && isStartTimeLater) || (isNoCurrentStartTimeDate && tokenNumber)) {
        return val;
      }

      return acc;
    }, this.counters[0]);

    this.latestTokenNumber = counterWithLatestToken?.currentTokenNumber?.tokenNumber || '';
  }

  public refreshPage() {
    location.reload();
  }

  alertMessage(error = new Error('Unknown error, contact Qtime support team!')) {
    let msg = '';
    let confirmMsg = 'Refresh the page!';

    const isUnauthenticated = error?.[0]?.status == '401';

    if (isUnauthenticated) {
      msg = 'You are not authorized to access this page';
      confirmMsg = 'Ok';
    }

    swal({
      text: msg,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: confirmMsg,
    }).then(() => {
      if (isUnauthenticated) {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }

  setIdleScreenTimeout(hideIdleScreenInitially = true) {
    if (hideIdleScreenInitially) this.showIdleScreen = false;
    if (this.idleScreenTimeout) clearTimeout(this.idleScreenTimeout);

    const { showIdleScreenAfterInactivity } = this.customization || {};
    if (!showIdleScreenAfterInactivity) return;

    this.idleScreenTimeout = setTimeout(() => {
      this.showIdleScreen = true;
    }, this.customization.inactivityTimer * 60 * 1000);
  }

  public setShowIdleScreen(value: boolean) {
    this.showIdleScreen = value;
  }

  public mapToCounterItem(counter: ApiCounter): CounterItem {
    const { id, attributes } = counter;

    return {
      id: +id,
      name: attributes.name,
      displayName: attributes.displayName,
      peopleInQueue: attributes.peopleInQueue,
      currentTokenNumber: attributes.currentTokenNumber,
      nextTokenSoundEnabled: attributes.nextTokenSoundEnabled,
    };
  }

  public mapToTokenData(payload: NewTokenData) {
    const { data } = payload;
    const { attributes, id } = data;

    return {
      id: +id,
      tokenNumber: attributes.tokenNumber,
      status: attributes.status,
      totalPeopleInQueue: attributes.totalPeopleInQueue,
      branchQueueId: attributes.branchQueueId,
      createdAt: attributes.createdAt,
      originType: attributes.originType,
      tokenTags: [],
      priority: undefined,
      branchQueueName: attributes.branchQueueName,
      counterId: attributes.counterId,
      startTime: attributes.startTime,
      comment: '',
    };
  }

  public shouldShowBanner() {
    return this.tokenAnimationState === 'original' && this.customization.showCounterNameWithBanner;
  }
}
